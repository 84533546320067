
import { Popover } from '@headlessui/react'
import {
    MenuIcon,

} from '@heroicons/react/outline'

import {auth} from "../../firebase/firebase";
import {useProfile} from "../../context/profile.context";
import { NavLink} from "react-router-dom";

const Navbar = () => {
    const {profile} = useProfile()
    return (
        <div>
        {profile  && (
            <Popover className="relative bg-white">
                {({ open }) => (
                    <>
                        <div className="absolute inset-0 shadow  pointer-events-none" aria-hidden="true" />
                        <div className="relative ">
                            <div className="w-10/12 mx-auto flex justify-between items-center px-4 py-5 sm:px-6 sm:py-4 lg:px-8 md:justify-start md:space-x-10">

                                <div className="-mr-2 -my-2 md:hidden">
                                    <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-cyan-500">
                                        <span className="sr-only">Open menu</span>
                                        <MenuIcon className="h-6 w-6" aria-hidden="true" />
                                    </Popover.Button>
                                </div>
                                <div className="hidden md:flex-1 md:flex md:items-center md:justify-between">
                                    <div className="flex space-x-10">
                                        <NavLink to="dashboard"   activeStyle={{
                                            fontWeight: "bold",
                                            textDecoration: "underline",

                                        }} className="text-base font-medium  font-mono	text-gray-500 hover:text-gray-900">
                                            DASHBOARD
                                        </NavLink>
                                        {/*<NavLink to="menu" activeStyle={{*/}
                                        {/*    fontWeight: "bold",*/}
                                        {/*    textDecoration: "underline",*/}

                                        {/*}} className="text-base font-medium font-mono text-gray-500 hover:text-gray-900">*/}
                                        {/*    MENU*/}
                                        {/*</NavLink>*/}
                                        <NavLink to="OrderBoard" activeStyle={{
                                            fontWeight: "bold",
                                            textDecoration: "underline",
                                        }}  className="text-base font-medium  font-mono text-gray-500 hover:text-gray-900">
                                            ORDER BOARD
                                        </NavLink>
                                    </div>


                                    <div className="flex items-center md:ml-12">
                                        <button
                                            onClick={() => auth.signOut()}
                                            type="button"
                                            className="inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500"
                                        >
                                            Logout
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                )}
            </Popover>
            )}
        </div>
    );
}
export default Navbar;
