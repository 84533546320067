import {Switch} from "react-router-dom";

import SignIn from "./pages/user/SignIn";
import PrivateRoute from "./components/PrivateRoute";
import DashBoard from "./pages/dashboard/DashBoard";
import PublicRoute from "./components/PublicRoute";
import {ProfileProvider} from "./context/profile.context";
import MenuSetup from "./pages/dashboard/menu/MenuSetup";
import Layout from "./pages/layout/Layout";
import OrderBoard from "./pages/orderBoard/OrderBoard";
import NotFoundPage from "./components/NotFoundPage";
import {Route} from "react-router";


function App() {
    return (
        <ProfileProvider>
            <Layout>
                <Switch>
                    <PublicRoute path="/" exact><SignIn/></PublicRoute>
                    <PrivateRoute exact={true} path='/dashboard' >
                        <DashBoard  title={'DASHBOARD'} />
                    </PrivateRoute>
                    {/*<PrivateRoute exact={true} path='/menu' title={'MENU SETUP'}>*/}
                    {/*    <MenuSetup/>*/}
                    {/*</PrivateRoute>*/}
                    <PrivateRoute exact={true} path='/OrderBoard' title={'MENU SETUP'}>
                        <OrderBoard />
                    </PrivateRoute>
                    <Route path="*" component={NotFoundPage} />
                </Switch>
            </Layout>
        </ProfileProvider>
    );
}

export default App;

