import Navbar from "./Navbar";
import {BadgeCheckIcon} from "@heroicons/react/solid";
import logo from "../../assests/img/logo_transparent_background.png";
import React from "react";
import MenuList from "../dashboard/menu/MenuList";
import {useProfile} from "../../context/profile.context";
import LeftPanel from "./LeftPanel";

const Layout = (props) => {
    const {profile, store} = useProfile()
    return (
        <div className="h-screen bg-gray-50 font-mono flex-grow">
            <Navbar/>
            <div className="sm:px-6 lg:px-8 ">
                <main>
                    {profile
                    !== null && profile ?
                        <div className="w-11/12 mx-auto sm:px-6 lg:px-8">
                            <div className="px-4  sm:px-0">
                                <div className="flex-grow w-full w-4/5 mx-auto xl:px-8 lg:flex">
                                    <div className="flex-1 min-w-0  xl:flex">
                                        <LeftPanel photoUrl={profile.profileData.photoUrl} name={profile.profileData.name} email={profile.profileData.email} store={store}/>
                                        <div className=" lg:min-w-0 lg:flex-1">
                                            <div
                                                className="pl-4 pr-6 pt-4 pb-4 border-b border-t border-gray-200 sm:pl-6 lg:pl-8 xl:pl-6 xl:pt-6 xl:border-t-0">
                                                <div className="flex items-center">
                                                    <img
                                                        className="mx-auto h-16 w-lg-20"
                                                        src={logo}
                                                        alt="Workflow"
                                                    />
                                                </div>
                                            </div>
                                            {props.children}
                                        </div>
                                    </div>
                                    <div
                                        className="bg-gray-50 pr-4 sm:pr-6 lg:pr-8 lg:flex-shrink-0 lg:border-l lg:border-gray-200 xl:pr-0">
                                        <MenuList />
                                    </div>
                                </div>
                            </div>
                        </div>
                        :
                        <>
                            {props.children}
                        </>
                    }
                </main>


            </div>
        </div>
    )

}

export default Layout;
