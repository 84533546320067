import { Fragment, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import {useProfile} from "../../../context/profile.context";
import axios from "axios";

export default function  ItemCreateModal({open, setOpen}){

    const cancelButtonRef = useRef(null);
    const [formValues, setFormValues] = useState({name:"", description:"", active:"true", price:0});
    const {store, handleItemAdded} = useProfile();

    console.log(store);

    async function submit(e) {
        e.preventDefault();
        console.log(formValues);
        const data = {
            "type": "add-item",
            "body": {
                "storeId": store.storeId,
                "itemName": formValues.name,
                "description": formValues.description,
                "active": formValues.active,
                "cost": formValues.price
            }
        }
        await axios.post("https://luuunch-store-command-api.herokuapp.com/v1/api/activity", data)
            .then( res => handleItemAdded(data))
            .then( res => setOpen(!open))
        ;



    }

    function handleFieldChange(e) {
        // e.preventDefault();
        const fieldChange = {
            ...formValues,
            [e.target.name]: e.target.value
        }
        setFormValues(fieldChange)
        console.log(fieldChange)

    }

    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog
                as="div"
                static
                className="fixed z-10 inset-0 overflow-y-auto"
                initialFocus={cancelButtonRef}
                open={open}
                onClose={setOpen}
            >
                <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    {/* This element is to trick the browser into centering the modal contents. */}
                    <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enterTo="opacity-100 translate-y-0 sm:scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    >
                        <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-14 sm:align-top sm:max-w-lg sm:w-full">
                            <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                                <div className="sm:flex sm:items-start">

                                    <div className="mt-5 md:mt-0 md:col-span-2 font-mono">
                                        <form action="#" method="POST">
                                            <div className="grid grid-cols-6 gap-6">
                                                <div className="col-span-12 sm:col-span-6">
                                                    <label htmlFor="first-name" className="block text-sm font-medium text-gray-700">
                                                        Item Name
                                                    </label>
                                                    <input
                                                        type="text"
                                                        value={formValues.name}
                                                        placeholder="Enter Product Name"
                                                        name="name"
                                                        onChange={(e) => handleFieldChange(e)}
                                                        id="name"
                                                        autoComplete="given-name"
                                                        className="mt-1 focus:ring-cyan-500 focus:border-cyan-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                                    />
                                                </div>

                                                <div className="col-span-12 sm:col-span-6">
                                                    <label htmlFor="email-address" className="block text-sm font-medium text-gray-700">
                                                        Item Desciption
                                                    </label>
                                                    <textarea
                                                        rows="5"
                                                        value={formValues.description}
                                                        onChange={(e) => handleFieldChange(e)}
                                                        name="description"
                                                        placeholder="Enter Product Description"
                                                        id="Description"
                                                        autoComplete="email"
                                                        className="mt-1 focus:ring-cyan-500 focus:border-cyan-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                                    />
                                                </div>

                                                <div className="col-span-6 sm:col-span-3">
                                                    <label htmlFor="country" className="block text-sm font-medium text-gray-700">
                                                        Active
                                                    </label>
                                                    <select
                                                        value={formValues.active}
                                                        onChange={(e) => handleFieldChange(e)}
                                                        name="active"
                                                        id="active"
                                                        autoComplete="country"
                                                        className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-cyan-500 focus:border-cyan-500 sm:text-sm"
                                                    >
                                                        <option value={"true"}>Yes</option>
                                                        <option value={"false"}>No</option>
                                                    </select>
                                                </div>

                                                <div className="col-span-6 sm:col-span-6 lg:col-span-2">
                                                    <label htmlFor="postal-code" className="block text-sm font-medium text-gray-700">
                                                        Price
                                                    </label>
                                                    <input
                                                        onChange={(e) => handleFieldChange(e)}
                                                        value={formValues.price}
                                                        type="number"
                                                        name="price"
                                                        placeholder={0.00}
                                                        id="cost"
                                                        className="mt-1 focus:ring-cyan-500 focus:border-cyan-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                                    />
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                            <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                                <button
                                    type="button"
                                    className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-cyan-600 text-base font-medium text-white hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500 sm:ml-3 sm:w-auto sm:text-sm"
                                    onClick={(e) => submit(e)}
                                >
                                    Create

                                </button>
                                <button
                                    type="button"
                                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                                    onClick={() => setOpen(!open)}
                                    ref={cancelButtonRef}
                                >
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition.Root>
    )
}


