import {BadgeCheckIcon} from "@heroicons/react/solid";
import React, {useState} from "react";
import ItemCreateModal from "../dashboard/menu/ItemCreateModal";

const LeftPanel = (props) => {

    const [open, setOpen] = useState(false)
    const {photoUrl, email, name, store} = props;

    console.log(store)

    return (
        <div className="xl:flex-shrink-0  xl:border-r xl:border-gray-200 ">
            <div className="pl-4 pr-6 py-6 sm:pl-6 lg:pl-8 xl:pl-0">
                <div className="flex items-center justify-between">
                    <div className="flex-1 space-y-8">
                        <div
                            className="space-y-8 sm:space-y-0 sm:flex sm:justify-between sm:items-center xl:block xl:space-y-4">
                            <div className="flex items-center space-x-3">
                                {photoUrl ?
                                    <div className="flex-shrink-0 h-12 w-12">
                                        <img
                                            className="h-12 w-12 rounded-full"
                                            src={photoUrl}
                                            alt=""
                                        />
                                    </div> :
                                    <></>}
                                <div className="space-y-1">
                                    <div className="text-sm font-medium text-gray-900">{email}</div>
                                    <div className="group flex items-center space-x-2.5">
                                        <span
                                            className="text-sm text-gray-500 group-hover:text-gray-900 font-medium">{name}</span>
                                    </div>
                                </div>
                            </div>
                            {/* Action buttons */}

                            <div className="flex flex-col sm:flex-row xl:flex-col">
                                {store !== null ?
                                    <button

                                        onClick={() => setOpen(!open)}
                                        type="button"
                                        className="mt-3 inline-flex items-center justify-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500 sm:mt-0 sm:ml-3 xl:ml-0 xl:mt-3 xl:w-full"
                                    >
                                        Create Menu Item
                                    </button>
                                    :
                                    <div></div>
                                }
                            </div>
                        </div>
                        {/* Meta info */}
                        <div
                            className="flex flex-col space-y-6 sm:flex-row sm:space-y-0 sm:space-x-8 xl:flex-col xl:space-x-0 xl:space-y-6">
                            <div className="flex items-center space-x-2">
                                <BadgeCheckIcon className="h-5 w-5 text-gray-400"
                                                aria-hidden="true"/>
                                <span className="text-sm text-gray-500 font-medium">Pro Member</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ItemCreateModal open={open} setOpen={setOpen}/>
        </div>
    )
}
export default LeftPanel;
