import BlankState from "./BlankState";
import SlideOut from "./SlideOut";
import React, {useState} from "react";
import VendorProfile from "./profile/VendorProfile";
import {useProfile} from "../../context/profile.context";


const DashBoard = () => {

    const {profile, store, handleStoreCreation} = useProfile();

    const [showModal, setShowModal] = useState(false);

    function handleModalClick() {
        setShowModal(!showModal);
    }

    function createStore(store) {
        handleStoreCreation(store)
    }

    return (
        <>
            {store === null ?
                <>
                    <BlankState handleModalClick={handleModalClick}/>
                    <SlideOut showModal={showModal} handleModalClick={handleModalClick} handleStoreCreation={createStore}/>
                </>
                :
                <VendorProfile/>
            }
        </>
    )
}
export default DashBoard;
