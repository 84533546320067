import {useProfile} from "../../../context/profile.context";
import BlankState from "../BlankState";


const MenuList = (props) => {
    const menuList = [];
    const {store} = useProfile();

    return (
        <>
            {
                store != null ?
                    <div className="pl-6 lg:w-80">
                        <div className="pt-6 pb-2">
                            <h2 className="text-sm font-semibold">Menu Items</h2>
                        </div>
                        <div>
                            <div className="mt-6">
                                <div

                                    className="w-full flex justify-center items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                                >
                                    View all
                                </div>
                            </div>
                            <div className="flow-root mt-6">
                                <ul className="divide-y divide-gray-200">
                                    {store.items.map(item => (
                                        <li key={item.name} className="py-4">
                                            <div className="flex items-center space-x-4">
                                                <div className="flex-1 min-w-0">
                                                    <p className="text-sm font-medium text-gray-900 truncate">{item.itemName}</p>
                                                    <p className="text-sm text-gray-500 truncate">{'@' + item.description}</p>
                                                    <p className="text-sm text-gray-500 truncate">{'$' + item.cost}</p>
                                                </div>
                                                <div>
                                                    <div
                                                        className="inline-flex items-center shadow-sm px-2.5 py-0.5 border border-gray-300 text-sm leading-5 font-medium rounded-full text-gray-700 bg-white hover:bg-gray-50">
                                                        Update
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                            </div>

                            <div className="py-4 text-sm border-t border-gray-200">
                                <div className="text-cyan-600 font-semibold hover:text-cyan-900">
                                    View all activity <span aria-hidden="true">&rarr;</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    <div className="pl-6 mt-10 lg:w-80">
                        <div
                            type="button"
                            className="relative block w-full border-2 border-gray-300 border-dashed rounded-lg p-12 text-center  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500"
                        >
                            {/*<svg*/}
                            {/*    className="mx-auto h-12 w-12 text-gray-400"*/}
                            {/*    xmlns="http://www.w3.org/2000/svg"*/}
                            {/*    stroke="currentColor"*/}
                            {/*    fill="none"*/}
                            {/*    viewBox="0 0 48 48"*/}
                            {/*    aria-hidden="true"*/}
                            {/*>*/}

                            {/*    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"*/}
                            {/*          d="M4 6h16M4 12h16M4 18h16"/>*/}
                            {/*</svg>*/}
                            <span className="mt-2 block text-sm font-medium text-gray-900">Create a Store To Add Items</span>
                        </div>
                    </div>
            }
        </>
    )


}
export default MenuList;
