import React, {Fragment, useState} from 'react'
import {Dialog, Transition} from '@headlessui/react'
import {XIcon} from '@heroicons/react/outline'
import axios from "axios";
import {useProfile} from "../../context/profile.context";


export default function SlideOut(props) {

    const {showModal, handleModalClick, handleStoreCreation} = props;
    const {profile} = useProfile();
    const [errorMessage, setErrorMessage] = useState("");
    const [storeName, setStoreName] = useState("");
    const [storeDesc, setStoreDesc] = useState("");
    const [submitted, setSubmitted] = useState(false);


    async function createStore() {
        setSubmitted(true)
        try{
            const store = {uid: profile.profileData.uid, name: storeName, descr: storeDesc};
            const data = {type: "store-created", body: store};
            await postStore(data);
            await handleStoreCreation(profile.profileData.uid);
            setSubmitted(false)
            // await handleModalClick(false);
        }catch (err){
            console.log(JSON.stringify(err))
            setErrorMessage(err.message);
            setSubmitted(false)
        }
    }

    async function postStore(data) {
        return axios.post('https://luuunch-store-command-api.herokuapp.com/v1/api/activity', data)

    }

    function handleStoreNameFieldChange(e) {
        e.preventDefault();
        setStoreName(e.target.value);
    }

    function handleStoreDescrFieldChange(e) {
        e.preventDefault();
        setStoreDesc(e.target.value);
    }

    return (
        <Transition.Root show={showModal} as={Fragment}>
            <Dialog as="div" static className="fixed inset-0 overflow-hidden font-mono	" open={showModal}
                    onClose={handleModalClick}>
                <div className="absolute inset-0 overflow-hidden">
                    <Dialog.Overlay className="absolute inset-0"/>
                    <div className="fixed inset-y-0 right-0 pl-10 max-w-full flex sm:pl-16 z-50">
                        <Transition.Child
                            as={Fragment}
                            enter="transform transition ease-in-out duration-500 sm:duration-700"
                            enterFrom="translate-x-full"
                            enterTo="translate-x-0"
                            leave="transform transition ease-in-out duration-500 sm:duration-700"
                            leaveFrom="translate-x-0"
                            leaveTo="translate-x-full"
                        >
                            <div className="w-screen max-w-2xl">
                                <form className="h-full flex flex-col bg-white shadow-xl overflow-y-scroll">
                                    <div className="flex-1">
                                        {/* Header */}
                                        <div className="px-4 py-6 bg-gray-50 sm:px-6">
                                            <div className="flex items-start justify-between space-x-3">
                                                <div className="space-y-1">
                                                    <Dialog.Title className="text-lg font-medium text-gray-900">New
                                                        Store</Dialog.Title>
                                                    <p className="text-sm text-gray-500">
                                                        Get started by filling in the information below to create your
                                                        new store.
                                                    </p>
                                                </div>
                                                <div className="h-7 flex items-center">
                                                    <button
                                                        type="button"
                                                        className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-cyan-500"
                                                        onClick={() => handleModalClick(false)}
                                                    >
                                                        <span className="sr-only">Close panel</span>
                                                        <XIcon className="h-6 w-6" aria-hidden="true"/>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            className="py-6 space-y-6 sm:py-0 sm:space-y-0 sm:divide-y sm:divide-gray-200">
                                            {/* Project name */}
                                            <div
                                                className="space-y-1 px-4 sm:space-y-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
                                                <div>
                                                    <label
                                                        htmlFor="project-name"
                                                        className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                                                    >
                                                        Store name
                                                    </label>
                                                </div>
                                                <div className="sm:col-span-2">
                                                    <input
                                                        value={storeName}
                                                        onChange={(e) => handleStoreNameFieldChange(e)}
                                                        type="text"
                                                        name="project-name"
                                                        id="project-name"
                                                        className="block w-full shadow-sm sm:text-sm focus:ring-cyan-500 focus:border-cyan-500 border-gray-300 rounded-md"
                                                    />
                                                </div>
                                            </div>

                                            {/* Project description */}
                                            <div
                                                className="space-y-1 px-4 sm:space-y-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
                                                <div>
                                                    <label
                                                        htmlFor="project-description"
                                                        className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                                                    >
                                                        Store Description
                                                    </label>
                                                </div>
                                                <div className="sm:col-span-2">
                                                      <textarea
                                                          value={storeDesc}
                                                          onChange={(e) => handleStoreDescrFieldChange(e)}
                                                          id="project-description"
                                                          name="project-description"
                                                          rows={5}
                                                          className="block w-full shadow-sm sm:text-sm focus:ring-cyan-500 focus:border-cyan-500 border border-gray-300 rounded-md"
                                                      />
                                                </div>
                                            </div>
                                            <p className="text-red-900 text-center my-10 ">
                                                {errorMessage}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="flex-shrink-0 px-4 border-t border-gray-200 py-5 sm:px-6">
                                        <div className="space-x-3 flex justify-end">
                                            <button
                                                type="button"
                                                className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500"
                                                onClick={() => handleModalClick(false)}
                                            >
                                                Cancel
                                            </button>
                                            <button
                                                disabled={submitted}
                                                onClick={() => createStore()}
                                                type="button"
                                                className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-cyan-600 hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500"
                                            >


                                                Create
                                            </button>
                                        </div>
                                    </div>

                                </form>

                            </div>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}
