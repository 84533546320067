import {useProfile} from "../../../context/profile.context";
/* This example requires Tailwind CSS v2.0+ */
import {ArrowSmDownIcon, ArrowSmUpIcon} from '@heroicons/react/solid'
import {CursorClickIcon, MailOpenIcon, UsersIcon} from '@heroicons/react/outline'
import {useLocation} from "react-router";

const stats = [
    {id: 1, name: 'Total Subscribers', stat: '71,897', icon: UsersIcon, change: '122', changeType: 'increase'},
    {id: 2, name: 'Avg. Open Rate', stat: '58.16%', icon: MailOpenIcon, change: '5.4%', changeType: 'increase'},
    {id: 3, name: 'Avg. Click Rate', stat: '24.57%', icon: CursorClickIcon, change: '3.2%', changeType: 'decrease'},
]

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function VendorProfile() {

    const {profile, store} = useProfile();
    let location = useLocation();

    console.log(location)

    return (
        <div>
            <main className="relative mt-6">

                <div className="mx-auto pb-6 px-4 sm:px-6 lg:pb-16 lg:px-8">
                    {/*<div>*/}
                    {/*    <dl className="mt-5 grid grid-cols-1 rounded-lg bg-white overflow-hidden shadow divide-y divide-gray-200 md:grid-cols-3 md:divide-y-0 md:divide-x">*/}
                    {/*        {stats.map((item) => (*/}
                    {/*            <div key={item.name} className="px-4 py-5 sm:p-6">*/}
                    {/*                <dt className="text-base font-normal text-gray-900">{item.name}</dt>*/}
                    {/*                <dd className="mt-1 flex justify-between items-baseline md:block lg:flex">*/}
                    {/*                    <div className="flex items-baseline text-2xl font-semibold text-cyan-600">*/}
                    {/*                        {item.stat}*/}
                    {/*                        <span*/}
                    {/*                            className="ml-2 text-sm font-medium text-gray-500">from {item.previousStat}</span>*/}
                    {/*                    </div>*/}

                    {/*                    <div*/}
                    {/*                        className={classNames(*/}
                    {/*                            item.changeType === 'increase' ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800',*/}
                    {/*                            'inline-flex items-baseline px-2.5 py-0.5 rounded-full text-sm font-medium md:mt-2 lg:mt-0'*/}
                    {/*                        )}*/}
                    {/*                    >*/}
                    {/*                        {item.changeType === 'increase' ? (*/}
                    {/*                            <ArrowSmUpIcon*/}
                    {/*                                className="-ml-1 mr-0.5 flex-shrink-0 self-center h-5 w-5 text-green-500"*/}
                    {/*                                aria-hidden="true"*/}
                    {/*                            />*/}
                    {/*                        ) : (*/}
                    {/*                            <ArrowSmDownIcon*/}
                    {/*                                className="-ml-1 mr-0.5 flex-shrink-0 self-center h-5 w-5 text-red-500"*/}
                    {/*                                aria-hidden="true"*/}
                    {/*                            />*/}
                    {/*                        )}*/}

                    {/*                        <span*/}
                    {/*                            className="sr-only">{item.changeType === 'increase' ? 'Increased' : 'Decreased'} by</span>*/}
                    {/*                        {item.change}*/}
                    {/*                    </div>*/}
                    {/*                </dd>*/}
                    {/*            </div>*/}
                    {/*        ))}*/}
                    {/*    </dl>*/}
                    {/*</div>*/}
                    <div className="bg-white rounded-lg shadow overflow-hidden mt-6">
                        <div className="divide-y divide-gray-200 lg:grid lg:grid-cols-12 lg:divide-y-0 lg:divide-x">

                            <form className="divide-y divide-gray-200 lg:col-span-12" action="#" method="POST">
                                {/* Profile section */}
                                <div className="py-6 px-4 sm:p-6 lg:pb-8">
                                    <div>
                                        <h2 className="text-lg leading-6 font-medium text-gray-900">Profile</h2>
                                        <p className="mt-1 text-sm text-gray-500">
                                            This information will be displayed publicly so be careful what you share.
                                        </p>
                                    </div>

                                    <div className="mt-6 flex flex-col lg:flex-row">
                                        <div className="flex-grow space-y-6">
                                            <div>
                                                <label htmlFor="username"
                                                       className="block text-sm font-medium text-gray-700">
                                                    Site
                                                </label>
                                                <div className="mt-1 rounded-md shadow-sm flex">
                          <span
                              className="bg-gray-50 border border-r-0 border-gray-300 rounded-l-md px-3 inline-flex items-center text-gray-500 sm:text-sm">
                            Luuunch.com/
                          </span>
                                                    <input
                                                        type="text"
                                                        name="username"
                                                        id="username"
                                                        autoComplete="username"
                                                        className="focus:ring-sky-500 focus:border-sky-500 flex-grow block w-full min-w-0 rounded-none rounded-r-md sm:text-sm border-gray-300"
                                                        defaultValue={store.storeName.split(" ").join("-")}
                                                    />
                                                </div>
                                            </div>


                                            <div>
                                                <label htmlFor="about"
                                                       className="block text-sm font-medium text-gray-700">
                                                    About
                                                </label>
                                                <div className="mt-1">
                                                  <textarea
                                                      id="about"
                                                      name="about"
                                                      rows={6}
                                                      className="shadow-sm focus:ring-sky-500 focus:border-sky-500 mt-1 block w-full sm:text-sm border border-gray-300 rounded-md"
                                                      defaultValue={store.description}
                                                  />
                                                </div>
                                                <p className="mt-2 text-sm text-gray-500">
                                                    Brief description for your profile. URLs are hyperlinked.
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="mt-6 grid grid-cols-12 gap-6">
                                        <div className="col-span-12 sm:col-span-6">
                                            <div
                                                className="relative border border-gray-300 rounded-md px-3 py-2 shadow-sm focus-within:ring-1 focus-within:ring-cyan-600 focus-within:border-cyan-600">
                                                <label
                                                    htmlFor="name"
                                                    className="absolute -top-2 left-2 -mt-px inline-block px-1 bg-white text-xs font-medium text-gray-900"
                                                >
                                                    Name
                                                </label>
                                                <input
                                                    type="text"
                                                    name="name"
                                                    id="name"
                                                    className="block w-full border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0 sm:text-sm"
                                                    placeholder="Jane Doe"
                                                />
                                            </div>
                                        </div>

                                        <div className="col-span-12 sm:col-span-6">
                                            <div
                                                className="relative border border-gray-300 rounded-md px-3 py-2 shadow-sm focus-within:ring-1 focus-within:ring-cyan-600 focus-within:border-cyan-600">
                                                <label
                                                    htmlFor="name"
                                                    className="absolute -top-2 left-2 -mt-px inline-block px-1 bg-white text-xs font-medium text-gray-900"
                                                >
                                                    Name
                                                </label>
                                                <input
                                                    type="text"
                                                    name="name"
                                                    id="name"
                                                    className="block w-full border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0 sm:text-sm"
                                                    placeholder="Jane Doe"
                                                />
                                            </div>
                                        </div>

                                        <div className="col-span-12">
                                            <div
                                                className="relative border border-gray-300 rounded-md px-3 py-2 shadow-sm focus-within:ring-1 focus-within:ring-cyan-600 focus-within:border-cyan-600">
                                                <label
                                                    htmlFor="name"
                                                    className="absolute -top-2 left-2 -mt-px inline-block px-1 bg-white text-xs font-medium text-gray-900"
                                                >
                                                    Name
                                                </label>
                                                <input
                                                    type="text"
                                                    name="name"
                                                    id="name"
                                                    className="block w-full border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0 sm:text-sm"
                                                    placeholder="Jane Doe"
                                                />
                                            </div>
                                        </div>

                                        <div className="col-span-12 sm:col-span-6">
                                            <div
                                                className="relative border border-gray-300 rounded-md px-3 py-2 shadow-sm focus-within:ring-1 focus-within:ring-cyan-600 focus-within:border-cyan-600">
                                                <label
                                                    htmlFor="name"
                                                    className="absolute -top-2 left-2 -mt-px inline-block px-1 bg-white text-xs font-medium text-gray-900"
                                                >
                                                    Name
                                                </label>
                                                <input
                                                    type="text"
                                                    name="name"
                                                    id="name"
                                                    className="block w-full border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0 sm:text-sm"
                                                    placeholder="Jane Doe"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="divide-y divide-gray-200">
                                    <div className="py-4 px-4 flex justify-end sm:px-6">
                                        <button
                                            type="button"
                                            className="bg-white border border-gray-300 rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500"
                                        >
                                            Cancel
                                        </button>
                                        <button
                                            type="submit"
                                            className="ml-5 bg-cyan-700 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-cyan-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500"
                                        >
                                            Save
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    )
}
