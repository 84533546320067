import React, {createContext, useContext, useEffect, useState} from "react";
import axios from "axios";

import {auth} from "../firebase/firebase";

const ProfileContext = createContext()

export default ProfileContext;

export const ProfileProvider = ({children}) => {

    const [profile, setProfile] = useState(null)
    const [store, setStore] = useState(null)

    useEffect(() => {
        const authUnsub = auth.onAuthStateChanged(async authObject => {
            if (authObject) {
                const token = await getToken(authObject);
                const profileData = {
                    token: token,
                    uid: authObject.uid,
                    name: authObject.displayName,
                    email: authObject.email,
                    photoUrl: authObject.photoURL,
                }
                setProfile({profileData})
                const data = await axios.get(`https://luuunch-store-query-api.herokuapp.com/v1/api/store/${profileData.uid}`)
                setStore(data.data.store);
            } else {
                setProfile(null)
            }
        });

        return () => authUnsub();
    }, [])

    const sleep = (milliseconds) => {
        return new Promise(resolve => setTimeout(resolve, milliseconds))
    }

    const getStore = async (id) => {
        await sleep(1000);
        const data = await axios.get(`https://luuunch-store-query-api.herokuapp.com/v1/api/store/${id}`);
        setStore(data.data.store);
    }


    const handleStoreCreation = async (id) => {
        await getStore(id);
    }

    const handleItemAdded = (data) => {
        console.log("(((((((((((((((((((((((((((((((")
        console.log(data.body)
        console.log({...store, items: [data.body, ...store.items ]})
        setStore({...store, items: [data.body, ...store.items ]})

    }

    return <ProfileContext.Provider value={{profile, store, handleStoreCreation, handleItemAdded}}>  {children} </ProfileContext.Provider>
}

async function getToken(authObject) {
    return await authObject.getIdToken();
}

export const useProfile = () => useContext(ProfileContext);
