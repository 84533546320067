import {MailIcon, PhoneIcon} from '@heroicons/react/solid'
import {Component} from "react";
import Pusher from 'pusher-js';
import ProfileContext, {useProfile} from '../../context/profile.context';

export default class OrderBoard extends Component {

    static contextType = ProfileContext;

    constructor(props) {
        super(props);
        this.state = {
            orders: []
        }
    };

    componentDidMount () {
        const profile = this.context;
        console.log(profile.store);
        const
            pusher = new Pusher('9eb5cef4b6b11b5d20ef', {
                cluster: 'us2',
                authEndpoint:
                    'https://luuunch-store-pusher-api.herokuapp.com/v1/api/auth',
                encrypted: true
            });

        const channel = pusher.subscribe(`private-${profile.store.storeId}-order-channel`);

        const order = {
            name: 'Jane Cooper',
            title: 'Regional Paradigm Technician',
            role: 'Admin',
            email: 'janecooper@example.com',
            telephone: '+1-202-555-0170',
            imageUrl:
                'https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=4&w=256&h=256&q=60',
        };

        channel.bind('ORDER_CREATED', data => {
            const msg = JSON.parse(data.data.replace("\\", ""));
            console.log(msg)
            this.setState(preState => ({orders: [...preState.orders, msg]}))
        });
    }


render()
{
    return (
        <div className="p-8">
            <ul className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
                {this.state.orders.map((order) => (
                    <li key={order.orderId} className="col-span-1 bg-white rounded-lg shadow divide-y divide-gray-200">
                        <div className="w-full flex items-center justify-between p-6 space-x-6">
                            <div className="flex-1 truncate">
                                <div className="flex items-center space-x-3">
                                    <h3 className="text-gray-900 text-sm font-medium truncate">{order.orderId}</h3>
                                    <span className="flex-shrink-0 inline-block px-2 py-0.5 text-green-800 text-xs font-medium bg-green-100 rounded-full">
                                          Created
                                        </span>
                                </div>
                                <p className="mt-1 text-gray-500 text-sm truncate">{order.phoneNumberOfOrder}</p>
                                <p className="mt-1 text-gray-500 text-sm truncate">{order.instructions}</p>
                            </div>
                        </div>
                        <div>
                            <div className="-mt-px flex divide-x divide-gray-200">
                                <div className="w-0 flex-1 flex">
                                    <a

                                        className="relative -mr-px w-0 flex-1 inline-flex items-center justify-center py-4 text-sm text-gray-700 font-medium border border-transparent rounded-bl-lg hover:text-gray-500"
                                    >
                                        <MailIcon className="w-5 h-5 text-gray-400" aria-hidden="true"/>
                                        <span className="ml-3">Email</span>
                                    </a>
                                </div>
                                <div className="-ml-px w-0 flex-1 flex">
                                    <a

                                        className="relative w-0 flex-1 inline-flex items-center justify-center py-4 text-sm text-gray-700 font-medium border border-transparent rounded-br-lg hover:text-gray-500"
                                    >
                                        <PhoneIcon className="w-5 h-5 text-gray-400" aria-hidden="true"/>
                                        <span className="ml-3">Call</span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </li>
                ))}
            </ul>
        </div>
    )
}
}

