import React from 'react';
import {Redirect, Route} from "react-router-dom";
import {useProfile} from "../context/profile.context";


const PrivateRoute = ({children, title, ...routeProps}) => {
    const {profile} = useProfile()

    if (profile === null) {
        return <Redirect to={'/'}/>

    }
    return (
        <Route {...routeProps}>
            {children}
        </Route>
    );
}

export default PrivateRoute;
