import firebase from "firebase/app";
import 'firebase/auth';

const config = {
    apiKey: "AIzaSyAejWEGMsISd9mI0Laswvm5u_5mZI16hVQ",
    authDomain: "easy-store-cfd33.firebaseapp.com",
    databaseURL: "https://easy-store-cfd33.firebaseio.com",
    projectId: "easy-store-cfd33",
    storageBucket: "easy-store-cfd33.appspot.com",
    messagingSenderId: "365119813609",
    appId: "1:365119813609:web:99176719c03194fdf03d78",
    measurementId: "G-GDYZX26HDB"
};

const app = firebase.initializeApp(config);

export const auth = app.auth();
